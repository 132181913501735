@import "../../variables.scss";

.heading {
  border: 1px solid $grey-20;
}

.headingLeft {
  border: 1px solid $grey-20;
  height: 78px;
}

.bgGrey {
  background: $grey-05;
}

.inputDiv {
  & input {
    width: 250px;
  }
}

.infoIcon {
  margin-left: 5px;
  margin-top: 8px;
  cursor: pointer;
  height: 16px;
  width: 16px;
}

.tooltipTitle {
  color: #fff;
  font-weight: 600;
}

.Offcanvas {
  top: 0;
  bottom: 0;
  height: 100%;
  background-color: unset;
}

.Offcanvas1 {
  height: 91.5%;
}

.OffcanvasHeader {
  background: $grey-05;
  display: flex;
  padding: 1.45rem 2rem;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $grey-20;
}

.offCanvasTitle {
  color: $grey-70;
}

.search {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.datePicker1 {
  & input[type="date"] {
    border: 1px solid $red;

    &::-webkit-calendar-picker-indicator {
      opacity: 1;
      display: block;
      background: url("../../assets/images/calendar.png") no-repeat;
    }

    &::placeholder {
      color: $grey-20 !important;
      font-size: 0.875rem !important;
      line-height: 1.25rem !important;
    }

    &::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #00c851;
    }

    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #00c851;
      opacity: 1;
    }

    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #00c851;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #00c851;
    }
  }
}
.datePicker {
  & input[type="date"] {
    border: 1px solid $grey-20;

    &::-webkit-calendar-picker-indicator {
      opacity: 1;
      display: block;
      background: url("../../assets/images/calendar.png") no-repeat;
    }

    &::placeholder {
      color: $grey-20 !important;
      font-size: 0.875rem !important;
      line-height: 1.25rem !important;
    }

    &::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #00c851;
    }

    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #00c851;
      opacity: 1;
    }

    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #00c851;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #00c851;
    }
  }
}

.left {
  border-right: 1px solid rgba(27, 27, 27, 0.2);
  height: 100%;
}

.toast {
  border: 1px solid $grey-20;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  width: 414px;
}

.toastStatus {
  background: $light-yellow;
  padding: 2px 4px;
}

.toastBody {
  width: 214px;
}

.customProgressBar {
  display: none;
}

.toastBodyExcellent {
  width: 97px;
  height: 32px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #39b35a;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.toastStatusRE {
  background: #cde0f4;
  padding: 2px 4px;
}
.toastStatusOffline {
  background: #fbddcc;
  padding: 2px 4px;
}

.toastNurseStatus {
  background: #d7f0de;
  padding: 2px 4px;
}

.reposition {
  background: $light-blue;
  padding: 2px 4px;
}

.info {
  background: $light-red;
  padding: 2px 4px;
}

.crossDiv {
  & img {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
  }
}

.infoDiv {
  background: $yellow;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    height: 10px;
    width: 1.82px;
    object-fit: contain;
  }
}

.cross {
  width: 8px;
  height: 8px;
  object-fit: contain;
}

.homeBody {
  display: flex;
  justify-content: center;
}

.modelBodyData {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  height: 494px;
  border-bottom: 1px solid lightgray;
}

.customNurseToast {
  margin-left: 200px;
  width: 304px;
  height: 80px;
  margin-bottom: 10px;
}

.customToastNurse {
  margin-left: 200px;
  width: 400px;
  right: 5.8rem !important;
  bottom: 1rem !important;
}
.toastStatusNurse {
  background: #f5d7d7;
  padding: 2px 4px;
}

@media (min-width: $md) {
  .customToast {
    margin-left: 200px;
    width: 414px;
    right: 12.7rem !important;
    height: 120px;
  }

  .customToastRR {
    margin-left: 200px;
    width: 414px;
    right: 12.7rem !important;
    bottom: 1rem !important;
  }

  .bgGrey {
    min-height: 77.6vh;
  }

  .heading {
    border: 1px solid $grey-20;
    // height: 78px;
  }

  .inputDiv {
    & input {
      width: 290px;
    }
  }
}

@media (min-width: $lg) {
  .bgGrey {
    min-height: 75.8vh;
  }
  .customToastNurse {
    margin-left: 200px;
    width: 414px;
    right: 12.7rem !important;
    bottom: 1rem !important;
  }

  .customNurseToast {
    margin-left: 200px;
    width: 404px;
    right: 11.3rem !important;
    height: 80px;
  }

  .homeBody {
    display: flex;
    justify-content: flex-start;
  }

  .heading {
    border: 1px solid $grey-20;
    width: 71.9%;
    height: 78px;
  }

  .headingLeft {
    border: 1px solid $grey-20;
    width: 28.11%;
    height: 78px;
  }
}

@media (min-width: $xxl) {
  .customToastNurse {
    margin-left: 200px;
    width: 454px;
    right: 9.2rem !important;
    bottom: 1rem !important;
  }
  .customToast {
    margin-left: 200px;
    width: 454px;
    right: 9.2rem !important;
    height: 125px;
  }
  .customNurseToast {
    margin-left: 200px;
    width: 454px;
    right: 9.2rem !important;
    height: 85px;
  }

  .customToastRR {
    margin-left: 200px;
    width: 454px;
    right: 9.2rem !important;
    bottom: 1rem !important;
  }

  .bgGrey {
    min-height: 77.1vh;
  }

  .homeBody {
    display: flex;
    justify-content: flex-start;
  }

  .heading {
    border: 1px solid $grey-20;
    height: 78px;
    width: 72.8%;
  }

  .headingLeft {
    border: 1px solid $grey-20;
    width: 27.21%;
    height: 78px;
  }
}

.searchModal {
  background: $white;
  border: 1px solid $blue;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.primary {
  background: $grey-05;
  border-radius: 8px;
  cursor: pointer;
}

.border {
  border: 2px solid $grey-20;
  background-color: white;
}

.smallCard {
  width: 132px;
  height: 180px;
}

.patientName {
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #8d8d8d;
  padding-bottom: 10px;
}

.headerModal {
  text-align: center;
}

.cardFooter {
  font-size: 12px;
  line-height: 18px;
  color: #0762c8;
}

.cardFooter1 {
  display: none;
}

.addNurse {
  background: #ffffff;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px 8px 0px 0px;
  align-items: center;
}

.left {
  border-right: 1px solid rgba(27, 27, 27, 0.2);
  height: 100%;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.logo {
  width: 14px;
  height: 14px;
  margin: 2px;
  animation: scale 1s ease-in-out infinite;
}

.downArrow {
  width: 25px;
  height: 20px;
  object-fit: contain;
}

.calendar {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: relative;
  width: 148px;
  height: px;
  background: #ffffff;
  border: 1px solid #0762c8;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.range {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 10px;
  width: 146px;
  height: 32px;
  flex: none;
  order: 0;
  flex-grow: 0;
  color: #606060;
}

.range.selected:first-child,
.range.firstItem:first-child,
.range:first-child.active,
.range:first-child:active,
.range:first-child:focus,
.range:first-child:hover {
  background: #d1d1d1;
  color: #606060;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.range.selected,
.range:not(:first-child):not(:last-child).active,
.range:not(:first-child):not(:last-child):active,
.range:not(:first-child):not(:last-child):focus,
.range:not(:first-child):not(:last-child):hover {
  background: #d1d1d1;
  color: #606060;
}

.range.selectedData,
.range.selected:last-child,
.range:last-child.active,
.range:last-child:active,
.range:last-child:focus,
.range:last-child:hover {
  background: #d1d1d1;
  color: #606060;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
