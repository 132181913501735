@import "../../../../variables.scss";

.Offcanvas {
  top: 0;
  bottom: 0;
  height: 100%;
  background-color: unset;
}

.Offcanvas1 {
  height: 100%;
  max-width: 35%;
}

.OffcanvasHeader {
  background: $grey-05;
  display: flex;
  padding: 1.14rem 1.8rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey-20;
}

.offCanvasTitle {
  color: $grey-70;
}
.offCanvasBody {
  padding: 10px 0px;
}

.offCanvasText {
  padding: 0px 28px;
  padding-top: 12px;
  padding-bottom: 5px;
  color: #606060;
}

.offCanvasContainer {
  border-bottom: 1px solid $grey-20;
}

.offCanvasBody1 {
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  padding-bottom: 10px;
}

.offCanvasBody2 {
  display: flex;
  justify-content: space-between;
}
.dropLeftIcon1 {
  width: 24px;
  height: 24px;
  margin-right: 20px;
  margin-top: 13px;
  cursor: pointer;
}

.offCanvasBodyText {
  color: #8d8d8d;
}
.dropLeftIcon {
  width: 24px;
  height: 24px;
  margin-right: 20px;
  cursor: pointer;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 70%;
  border-radius: 8px;
  margin-top: 0;
  background-color: #cf3636;
  border: none;
  margin: 57px;
  margin-bottom: 32px;
  cursor: pointer;
  & img {
    width: 18px;
    height: 18px;
    padding-right: 5px;
    object-fit: contain;
  }
}

.buttonText {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
  color: #fff;
  font-family: "Inter";
}

@media screen and (max-width: 500px) {
  .Offcanvas1 {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .Offcanvas1 {
    display: none;
  }
}

@media (min-width: $lg) {
  .Offcanvas1 {
    height: 100%;
    max-width: 35%;
  }
}

@media (min-width: $xxl) {
  .Offcanvas1 {
    height: 100%;
    max-width: 25%;
  }
}
