@import "../../variables.scss";

.left {
  background-color: $grey-05;
  border-right: 1px solid #d1d1d1;
  & .item {
    min-height: 33rem;
  }
  & h2 {
    & span {
      color: $grey-20;
    }
  }
}
.logo {
  height: 24px;
  width: 106.15px;
  object-fit: contain;
}
.carousel {
  height: 84%;
}
.center {
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.right {
  background-color: $white;
  min-height: 100vh;

  & .containerRight {
    height: 87%;
    & .topText {
      color: $grey-70;
      font-weight: 500;
      margin-bottom: 2rem;
    }
    & h2,
    h5 {
      color: $cureous-grey;
    }
    & .location {
      color: $grey-50;
    }
    & .location1 {
      cursor: pointer;
      color: #0762c8;
      font-weight: 700;
    }
  }
  & .footerRight {
    max-width: 75%;
  }
}
.topText1 {
  color: #8d8d8d;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 160% */
  letter-spacing: -0.2px;
}
@media (min-width: $md) {
  .right {
    & .containerRight {
      max-width: 90%;
    }
  }
}
@media (min-width: $lg) {
  .left {
    & h2 {
      max-width: 90%;
    }
  }
}
@media (min-width: $xxl) {
  .carousel {
    height: 83.5%;
  }
  .left {
    background-color: $grey-05;
    & .item {
      min-height: 80vh;
    }
  }
  .right {
    & .containerRight {
      max-width: 80%;
      height: 80%;
      & .topText {
        margin-bottom: 5rem;
      }
    }
  }
}

.OffcanvasHeaderAddNurse {
  background: $grey-05;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  height: 20%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey-20;
}
.offCanvasTitleAddNurse {
  color: $grey-70;
  font-size: 20px;
}
.modelBody {
  height: 152px;
  padding-top: 12px;
  padding-left: 20px;
}

.keyPara {
  color: $grey-50;
}

.valuePara {
  color: #323232;
  font-weight: 500;
  font-family: Inter;
}

@media (min-width: $md) {
  .Offcanvas1AddNurse1 {
    height: 700px;
    width: 430px;
    margin: 50px 250px;
    border-radius: 8px;
  }
}

@media (min-width: $lg) {
  .Offcanvas1AddNurse1 {
    height: 700px;
    width: 430px;
    margin: 50px 290px;
    border-radius: 8px;
  }
}

@media (min-width: $xxl) {
  .Offcanvas1AddNurse1 {
    height: 800px;
    width: 430px;
    margin: 10px 500px;
    border-radius: 8px;
  }
}
