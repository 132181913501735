@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"), url("./fonts/Gilroy-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
button,
a,
input {
  &:hover,
  &:focus {
    outline: none;
  }
}
button {
  background: transparent;
  border: 0;
}
* {
  list-style: none !important;
  outline: none !important;
  box-sizing: border-box !important;
  text-decoration-line: none !important;
}
a {
  color: inherit;
  text-decoration-line: none !important;
}
html {
  scroll-behavior: smooth;
}
