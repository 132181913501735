// font family
$Inter: "Inter", sans-serif;
$Gilroy: "Gilroy";
// colors
$dark-blue: #064ec5;
$blue: #0762c8;
$light-blue: #cde0f4;
$dark-red: #c40000;
$red: #cf3636;
$light-red: #f5d7d7;
$dark-yellow: #ff8a00;
$yellow: #ffaf2b;
$light-yellow: #ffefd5;
$dark-green: #009300;
$green: #39b35a;
$light-green: #d7f0de;
$dark-orange: #e90000;
$orange: #eb5500;
$light-orange: #fbddcc;
$cureous-grey: #1b1b1b;
$grey-90: #323232;
$grey-80: #494949;
$grey-70: #606060;
$grey-60: #767676;
$grey-50: #8d8d8d;
$grey-40: #a4a4a4;
$grey-30: #bbbbbb;
$grey-20: #d1d1d1;
$grey-10: #e9e9e9;
$grey-05: #f3f3f3;
$grey-00: #f9f9f9;
$white: #ffffff;
// breakpoints
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
