.header {
  border: 1px solid #d1d1d1;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.appLogo {
  height: 24px;
  width: 106.15px;
  object-fit: contain;
  align-content: center;
  margin-bottom: 22px;
  margin-top: 20px;
}

.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgb(70, 111, 161);
  height: 56px;
}

.toolbar_navigator {
  display: flex;
  align-items: center;
  height: 100%;
}

.toolbar_logo {
  margin-left: 1rem;
}

.toolbar_logo a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
}

.spacer {
  flex: 1;
}

.toolbar_navigation-items ul {
  list-style-type: none;
  margin: 0;
  /* padding: 2px; */
  display: flex;
}

.toolbar_navigation-items li {
  padding: 0 1rem;
}

.toolbar_navigation-items a {
  color: #8d8d8d;
  text-decoration: none;
  font-size: 14px;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: black;
}

.nextTime {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  margin-top: 22px;
  padding: 2px 6px;
  height: 22px;
  background: #d7f0de;
  border-radius: 4px;
}

.nextTimeData {
  text-align: center;
  color: #009300;
  padding-top: 8px;
  font-weight: 500;
  font-family: 'Inter';
  font-style: normal;
}

.backgroundData {
  width: 130px;
  height: 67px;
  background: #F3F3F3;
  border-left: 1px solid #D1D1D1;
  border-right: 1px solid #D1D1D1;

}

.backgroundData1 {
  width: 130px;
  height: 67px;
}


.backgroundData2 {
  border-left: 1px solid #D1D1D1;
}


@media screen and (max-width: 799px) {
  .toolbar_navigation-items {
    display: none;
  }

  .dateAndTime {
    display: none;
  }

  .dateAndTime1 {
    margin-top: 19px;
    padding-top: 5px;
    box-sizing: border-box;
    background: #F9F9F9;
    border: 1px solid #D1D1D1;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

@media screen and (max-width: 465px) {
  .toolbar_navigation-items {
    display: none;
  }

  .dateAndTime {
    display: none;
  }

  .dateAndTime1 {
    display: none;
  }
}

@media screen and (min-width: 800px) {
  .toggle-btn {
    display: none;
  }

  .toolbar_logo {
    margin-left: 0;
  }

  .dateAndTime {
    /* margin-right: 159px; */
    margin-top: 19px;
    padding-top: 3px;
    box-sizing: border-box;
    background: #F9F9F9;
    border: 1px solid #D1D1D1;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .dateAndTime1 {
    margin-left: 29px;
    margin-top: 19px;
    padding-top: 3px;
    box-sizing: border-box;
    background: #F9F9F9;
    border: 1px solid #D1D1D1;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}