@import "./variables.scss";

.font-face-gr {
  font-family: $Gilroy !important;
}
// typography
.typography--class-display {
  font-size: 3.563rem;
  line-height: 4rem;
  letter-spacing: -0.02em;
}
.typography--class-h1 {
  font-size: 2.875rem;
  line-height: 3.5rem;
  letter-spacing: -0.02em;
}
.typography--class-h2 {
  font-size: 2.375rem;
  line-height: 3rem;
  letter-spacing: -0.02em;
}
.typography--class-h3 {
  font-size: 1.875rem;
  line-height: 2.5rem;
  letter-spacing: -0.02em;
}
.typography--class-h4 {
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
}
.typography--class-h5 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: -0.02em;
}
.typography--class-h6 {
  font-size: 0.875rem;
  line-height: 0.5rem;
  letter-spacing: -0.02em;
}
.typography--class-body {
  font-size: 0.875rem;
  line-height: 1.375rem;
  letter-spacing: -0.02em;
}
.typography--class-caption {
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: -0.02em;
}
.typography--class-footer {
  font-size: 0.625rem;
  line-height: 1rem;
  letter-spacing: -0.02em;
}
.carousel-indicators {
  display: flex !important;
  position: relative !important;
  & button {
    width: 8px !important;
    height: 8px !important;
    margin: 0px 10px !important;
    background: $grey-20 !important;
    border-width: 0 !important;
    border-radius: 50%;
  }
  & .active {
    background: $blue !important;
  }
}
.offcanvas-end {
  top: unset !important;
  bottom: 0 !important;
  width: 100% !important;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px 0px 0px 8px;
}
.grey20 {
  color: $grey-20;
}
.grey30 {
  color: $grey-30;
}
.grey50 {
  color: $grey-50;
}
.grey60 {
  color: $grey-60;
}
.grey70 {
  color: $grey-70;
}
.red {
  color: $red;
}
.blue {
  color: $blue;
}
.green {
  color: $green;
}
.dYellow {
  color: $dark-yellow;
}
.dBlue {
  color: #064ec5;
}
.offlineOrange {
  color: #e96200;
}
.toastRed {
  color: #c40000;
}
.dGreen {
  color: #39b35a;
}
.yellow {
  color: $yellow;
}
.cureousGrey {
  color: $cureous-grey;
}
.react-calendar {
  padding: 0.5rem;
}
.react-calendar__navigation__label {
  font-size: 16px;
  letter-spacing: -0.02em;
  color: #1b1b1b;
  font-family: $Gilroy !important;
  font-weight: bold;
}
.react-calendar__tile--active {
  color: #1b1b1b !important;
  background-color: #d1d1d1 !important;
}

.dateWidth {
  width: 150px;
  height: 28px;
}

@media (min-width: $md) {
  .offcanvas-end {
    height: calc(100% - 75.78px);
    width: 75% !important;
  }
}
@media (min-width: $xxl) {
  .dateWidth {
    width: 180px;
    height: 28px;
  }
  .dateTimeWidth {
    margin-left: 199px;
  }
  .Toastify__toast-container {
    width: 414px !important;
  }
  .typography--class-display {
    font-size: 5rem;
    line-height: 5.5rem;
  }
  .typography--class-h1 {
    font-size: 3.563rem;
    line-height: 4rem;
  }
  .typography--class-h2 {
    font-size: 2.875rem;
    line-height: 3.5rem;
  }
  .typography--class-h3 {
    font-size: 2.375rem;
    line-height: 3rem;
  }
  .typography--class-h4 {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
  .typography--class-h5 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .typography--class-h6 {
    font-size: 0.875rem;
    line-height: 0.5rem;
  }
  .typography--class-body {
    font-size: 1rem;
    line-height: 1.75rem;
  }
  .typography--class-caption {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .typography--class-footer {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .offcanvas-end {
    width: 70% !important;
    height: calc(100vh - 80px);
  }
}
// @media (min-width: $lg) {
//   .offcanvas-end {
//     width: 65% !important;
//   }
// }
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
