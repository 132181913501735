@import "../../variables.scss";

.primary {
  background: $grey-05;
  border-radius: 8px;
  cursor: pointer;
}
.border {
  border: 2px solid $grey-20;
  background-color: white;
}
.cross {
  width: 8px;
  height: 8px;
  object-fit: contain;
}
.smallCard {
  width: 132px;
  height: 180px;
}
.bigCard {
  width: 181px;
  height: 274px;
}
.error {
  border: 2px solid #eb5500;
  background-color: white;
}
.error1 {
  border: 2px solid #cf3636;
}
.careDiv {
  border: 2px solid $green;
  background-color: white;
}
@keyframes scale {
  0% { transform: scale(1); }
  50% { transform: scale(1.07); }
  100% { transform: scale(1); }
}
.bgRed1 {
  animation: scale 1s ease-in-out infinite;
  background: $red;
}
.bgRed {
  background: $red;
}
.stripe1 {
  background: $red;
  height: 101%;
  width: 20%;
  border-radius: 6px 0px 0px 6px;
}
.stripe2 {
  background: $red;
  height: 101%;
  width: 20%;
  border-radius: 0px 6px 6px 0px;
}
.bgYellow {
  background: $yellow;
}
.bgBlue {
  background: $blue;
}
.smallCard {
  & .crossDiv {
    & img {
      width: 1rem;
      height: 1rem;
      object-fit: contain;
    }
  }
  & .outOfBedDiv {
    background: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 8px;
    width: 77px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
      width: 12px;
      height: 19.62px;
      object-fit: contain;
    }
  }
  & .fallAlert {
    background: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 8px;
    width: 77px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
      width: 18px;
      height: 22px;
      object-fit: contain;
    }
  }
  & .reposition {
    background: $light-red;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 8px 0;
    padding-top: 8.5px;
    width: 77px;
    height: 32px;
  }
  & .callDiv {
    background: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 8px;
    width: 33px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
      height: 13px;
      width: 13px;
      object-fit: contain;
    }
  }
  & .errorTriangle {
    height: 24px;
    width: 24px;
    object-fit: contain;
  }
  & .care {
    height: 25.7px;
    width: 24px;
    object-fit: contain;
  }
}
.bigCard {
  pointer-events: none;
  & .crossDiv {
    & img {
      width: 1.5rem;
      height: 1.5rem;
      object-fit: contain;
    }
  }
  & .outOfBedDiv {
    background: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 8px;
    width: 131px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
      width: 1.5rem;
      height: 39px;
      object-fit: contain;
    }
  }
  & .fallAlert {
    background: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 8px;
    width: 131px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
      width: 24px;
      height: 33px;
      object-fit: contain;
    }
  }
  & .reposition {
    background: $light-red;
    border-radius: 8px;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    width: 131px;
    height: 56px;
  }
  & .callDiv {
    background: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 8px;
    width: 55px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
      height: 1.5rem;
      width: 1.5rem;
      object-fit: contain;
    }
  }
  & .errorTriangle {
    height: 46px;
    width: 46px;
    object-fit: contain;
  }
  & .care {
    height: 45px;
    width: 42.03px;
    object-fit: contain;
  }
}

.plusIcon {
  height: 1rem;
  width: 1rem;
  object-fit: contain;
}

.footerCard {
  background: $cureous-grey;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  height: 44px;
  width: 100%;
  border-radius: 0 0 8px 8px;
}
