@import "../../variables.scss";

.logout1 {
  color: $red;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  & img {
    width: 11px;
    height: 14px;
    object-fit: contain;
  }
}
.header {
  border: 1px solid $grey-20;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  height: 67px;
}
.logo {
  height: 24px;
  width: 106.15px;
  object-fit: contain;
  align-content: center;
  margin-bottom: 22px;
  margin-top: 20px;
}
.logoutModal {
  background: $white;
  border: 1px solid $blue;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.nextTime {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  margin-top: 3px;
  padding: 2px 6px;
  height: 22px;
  background: #d7f0de;
  border-radius: 4px;
  margin-bottom: 20px;
  margin-top: 22px;
}

.nextTimeData {
  text-align: center;
  color: #009300;
  padding-top: 8px;
  font-weight: 500;
  font-family: "Inter";
  font-style: normal;
}
.feedback {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  margin-top: 1px;
  padding: 8px 14px;
  height: 29px;
  background: #d7f0de;
  border-radius: 8px;
}
.feedbackData {
  text-align: center;
  color: #767676;
  padding-top: 8px;
  font-weight: 500;
  font-family: "Inter";
  font-style: normal;
  display: block;
}

.backgroundData2 {
  border-left: 1px solid #d1d1d1;
  padding-left: 6px;
  height: 67px;
}

@media screen and (max-width: 500px) {
  .dateAndTime {
    display: none;
  }
  .dateAndTime1 {
    display: none;
  }
  .refreshContainer {
    display: none;
  }
  .refreshText {
    display: none;
  }
  .refreshImage {
    height: 0px;
    width: 0px;
  }
  .SideMenu {
    height: 0px;
    width: 0px;
  }
  .logout {
    cursor: pointer;
    display: flex;
    color: $red;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    & img {
      width: 11px;
      height: 14px;
      object-fit: contain;
    }
  }
  .header {
    border: 1px solid $grey-20;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    height: 67px;
  }
}

@media screen and (max-width: 767px) {
  .dateAndTime {
    display: none;
  }
  .dateAndTime1 {
    display: none;
  }
  .refreshContainer {
    display: none;
  }
  .refreshText {
    display: none;
  }
  .refreshImage {
    height: 0px;
    width: 0px;
  }
  .SideMenu {
    height: 0px;
    width: 0px;
  }
  .logout {
    cursor: pointer;
    & img {
      width: 11px;
      height: 14px;
      object-fit: contain;
    }
  }
  .logoutText {
    color: $red;
    font-weight: 500;
    font-size: 14px;
    padding-top: 8px;
  }
}

@media screen and (min-width: 767px) {
  .refreshContainer {
    padding: 6px 8px 6px 8px;
    margin-right: 30px;
    width: 82px;
    height: 32px;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid #0762c8;
    cursor: pointer;
    margin-bottom: 15px;
    margin-top: 16px;
  }
  .refreshImage {
    height: 15px;
    width: 18px;
    margin-top: 1.5px;
  }
  .SideMenu {
    cursor: pointer;
    height: 24px;
    width: 24px;
    margin-left: 18px;
    margin-right: 6px;
    margin-bottom: 18px;
    margin-top: 20px;
  }
  .SideMenu1 {
    cursor: pointer;
    height: 24px;
    width: 24px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 18px;
  }
  .logout {
    display: none;
    & img {
      height: 0px;
      width: 0px;
    }
  }

  .logoutText {
    display: none;
  }
  .rotate {
    height: 15px;
    width: 18px;
    margin-top: 1.5px;
    animation: rotation 2s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .refreshText {
    color: #0762c8;
    // text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.24px;
    display: block;
  }

  .dateAndTime {
    padding-top: 3px;
    box-sizing: border-box;
    background: #f9f9f9;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 22px;
    margin-top: 18px;
  }
  .dateAndTime1 {
    padding-top: 3px;
    margin-right: 75px;
    box-sizing: border-box;
    background: #f9f9f9;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 22px;
    margin-top: 19px;
  }
}
