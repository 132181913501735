@import "../../variables.scss";

.errorParent {
  & .primaryLabel {
    color: $red;
  }
}
.primaryInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  background: $white;
  border: 1px solid $grey-20;
  color: $grey-70;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  margin: 4px 0px;
  &:hover,
  &:focus {
    border: 1px solid $blue;
  }
  &::placeholder {
    color: $grey-20;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
.primaryInput1 {
  display: flex;
  flex-direction: row;
  padding-right: 8px;
  align-items: center;
  background: $white;
  border: 1px solid $grey-20;
  color: $grey-70;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  margin: 4px 0px;
  &:hover,
  &:focus {
    border: 1px solid $blue;
  }
  &::placeholder {
    color: $grey-20;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
.passwordInput {
  &::placeholder {
    color: $grey-20;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
.errorInput1 {
  display: flex;
  flex-direction: row;
  padding-right: 8px;
  align-items: center;
  background: $white;
  border: 1px solid $red;
  color: $grey-70;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  margin: 4px 0px;
  &::placeholder {
    color: $grey-20;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
.predefined {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  background: $white;
  border: 1px solid $white;
  color: $grey-70;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  margin: 4px 0px;
  &::placeholder {
    color: $grey-70;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
.errorInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  background: $white;
  border: 1px solid $red;
  color: $grey-70;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  margin: 4px 0px;
  &::placeholder {
    color: $grey-20;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
.errorText {
  color: $red;
  font-family: $Inter;
  font-style: normal;
}
.primaryLabel {
  color: $grey-50;
  font-weight: 500;
  font-family: $Inter;
}
.highPriorityLabel {
  color: $red;
  font-weight: 500;
  font-family: $Inter;
}
.primaryLabelActive {
  color: $blue;
}
.inputGroup1 {
  width: 100% !important;
  height: 48px;
  & input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    background: $white;
    border: 1px solid $red;
    color: $grey-70;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    margin: 4px 0px;
    &::placeholder {
      color: $grey-20;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
}

.inputGroup {
  width: 100% !important;
  height: 48px;
  & input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    background: $white;
    border: 1px solid $grey-20;
    color: $grey-70;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    margin: 4px 0px;
    &:hover,
    &:focus {
      border: 1px solid $blue;
    }
    &::placeholder {
      color: $grey-20;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
}

.infoIcon {
  margin-left: 5px;
  cursor: pointer;
  height: 16px;
  width: 16px;
}
