.header {
  border: 1px solid #d1d1d1;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  height: 67px;
}
.side-drawer {
  height: 100%;
  background: white;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(800px);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(100%);
}

.side-drawer ul {
  height: 100%;
  list-style: none;
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
}

.side-drawer li {
  margin: 0.5rem 0;
}

.side-drawer a {
  text-decoration: none;
  color: #521751;
  font-size: 1.2rem;
}

.side-drawer a:hover,
.side-drawer a:active {
  color: #fa923f;
}

@media (max-width: 576px) {
  .side-drawer.open {
    transform: translateX(200px);
  }
}
@media screen and (min-width: 800px) {
  .side-drawer {
    display: none;
  }
}