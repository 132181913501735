@import "../../variables.scss";

.primary {
  padding: 8px 24px;
  background: $blue;
  border: 1px solid $blue;
  box-sizing: border-box;
  border-radius: 8px;
  color: $white;
  font-weight: normal;
  &:hover,
  &:focus {
    background: $dark-blue;
  }
}
.secondary {
  padding: 8px 24px;
  background: $white;
  border: 1px solid $red;
  box-sizing: border-box;
  border-radius: 8px;
  color: $red;
  &:hover,
  &:focus {
    background: $light-red;
    color: $dark-red;
    border: 1px solid $dark-red;
  }
}
