@import "../../../../variables.scss";

.OffcanvasHeaderAddNurse {
  padding-right: 20px;
  background: $grey-05;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  height: 20%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey-20;
}
.offCanvasTitleAddNurse {
  margin-left: 4px;
  color: $grey-70;
  font-size: 20px;
}
.modelBody {
  height: 330px;
  padding-top: 12px;
  padding-left: 20px;
  padding-right: 20px;
}

.keyPara {
  cursor: pointer;
  color: #0762c8;
  font-weight: 700;
}

@media (min-width: $md) {
  .Offcanvas1AddNurse1 {
    height: 700px;
    width: 430px;
    margin: 50px 250px;
    border-radius: 8px;
  }
}

@media (min-width: $lg) {
  .Offcanvas1AddNurse1 {
    height: 700px;
    width: 480px;
    margin: 50px 250px;
    border-radius: 8px;
  }
}

@media (min-width: $xxl) {
  .Offcanvas1AddNurse1 {
    height: 800px;
    width: 480px;
    margin: 10px 480px;
    border-radius: 8px;
  }
}
