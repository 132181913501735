@import "../../variables.scss";

.heading {
  border: 1px solid $grey-20;
}

.headingLeft {
  border: 1px solid $grey-20;
  height: 78px;
}

.bgGrey {
  background: $grey-05;
  padding: 2rem 3rem;
}

.overallHeader {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #ffffff;
}

.overallBoxHeader {
  font-weight: 700;
  font-size: 30px;
  padding-right: 15px;
  padding-top: 4px;
  line-height: 40px;
  color: #000000;
}

.headerBoxPara {
  font-family: "Inter";
  font-style: normal;
  padding-top: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #8d8d8d;
}

.box {
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
}

.boxShiftHeader {
  padding: 8px;
  height: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  width: 70px;
  color: #8d8d8d;
  cursor: pointer;
}

.boxShiftClicked {
  padding: 8px;
  height: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  width: 70px;
  color: #0762c8;
  cursor: pointer;
}

.boxHeader {
  padding: 8px;
  height: 22px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ff8a00;
}

.repositionBoxHeader {
  padding: 8px;
  height: 22px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: #0762c8;
}

.nursingBoxHeader {
  padding: 8px;
  height: 22px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: #39b35a;
}

.boxHeader1 {
  font-weight: 700;
  font-size: 30px;
  padding-left: 10px;
  padding-top: 8px;
  padding-right: 10px;
  line-height: 40px;
  color: #000000;
}

.boxPara {
  font-family: "Inter";
  font-style: normal;
  width: 118px;
  padding-top: 10px;
  padding-right: 15px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #8d8d8d;
}

.RepoBoxPara {
  font-family: "Inter";
  font-style: normal;
  width: 85px;
  padding-top: 10px;
  padding-right: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #8d8d8d;
}

.borderBottom {
  padding-top: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.dayNightImage {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-top: 5px;
}

.datePicker {
  & input[type="date"] {
    border: 1px solid $grey-20;

    &::-webkit-calendar-picker-indicator {
      opacity: 1;
      display: block;
      background: url("../../assets/images/calendar.png") no-repeat;
    }

    &::placeholder {
      color: $grey-20 !important;
      font-size: 0.875rem !important;
      line-height: 1.25rem !important;
    }

    &::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #00c851;
    }

    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #00c851;
      opacity: 1;
    }

    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #00c851;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #00c851;
    }
  }
}

@media (max-width: 576px) {
  .bgGrey {
    // min-height: 72.6vh;
    padding: 2rem 3rem;
  }

  .background {
    padding: 10px;
    background: $grey-05;
  }
}

@media (max-width: 1000px) {
  .overall {
    background: #0762c8;
    padding: 1rem 1rem;
  }

  .headerBox {
    display: flex;
    flex-direction: column;
  }

  .headerPara {
    height: 36px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    opacity: 0.5;
  }

  .overallBox {
    display: flex;
    // justify-content: space-between;
    height: 61px;
    padding: 0.5rem 1rem;
    background: #ffffff;
    border-radius: 8px;
  }

  .bgGrey {
    // min-height: 79.5vh;
    padding: 0rem 2rem;
    padding-top: 2rem;
  }

  .background {
    padding: 30px;
    background: $grey-05;
  }

  .body {
    padding-bottom: 48px;
    display: flex;
    flex-direction: column;
  }

  .box1 {
    display: flex;
    // justify-content: space-between;
    height: 58px;
    background: #ffffff;
    border-radius: 8px;
  }

  .borderRight {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .dayNightImage {
    width: 20px;
    height: 20px;
    margin-top: 5px;
  }
}

@media (min-width: 1000px) {
  .overall {
    background: #0762c8;
    display: flex;
    justify-content: space-between;
    height: 104px;
    padding: 1.38rem 5rem;
  }

  .headerBox {
    display: flex;
    justify-content: space-between;
  }

  .overallBox {
    display: flex;
    justify-content: space-between;
    height: 61px;
    padding: 0.5rem 1rem;
    background: #ffffff;
    border-radius: 8px;
  }

  .headerPara {
    width: 343px;
    height: 36px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    opacity: 0.5;
  }

  .bgGrey {
    padding: 29px 80px;
  }

  .background {
    min-height: 59.8vh;
    padding: 0px 80px;
    background: $grey-05;
  }

  .body {
    padding-bottom: 37px;
    display: flex;
  }

  .box1 {
    display: flex;
    justify-content: space-between;
    height: 58px;
    background: #ffffff;
    border-radius: 8px;
  }

  .boxBody {
    display: flex;
  }

  .borderRight {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
}

@media (min-width: $lg) {
  .heading {
    border: 1px solid $grey-20;
    width: 71.9%;
    height: 78px;
  }

  .headerPara {
    height: 36px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    opacity: 0.5;
  }

  .headingLeft {
    border: 1px solid $grey-20;
    width: 28.11%;
    height: 78px;
  }
}

@media (min-width: $xxl) {
  .heading {
    border: 1px solid $grey-20;
    width: 72.8%;
    height: 78px;
  }

  .boxShiftHeader {
    padding: 8px;
    height: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    width: 80px;
    color: #8d8d8d;
    cursor: pointer;
  }
  
  .boxShiftClicked {
    padding: 8px;
    height: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    width: 80px;
    color: #0762c8;
    cursor: pointer;
  }

  .headingLeft {
    border: 1px solid $grey-20;
    width: 27.21%;
    height: 78px;
  }
  .headerPara {
    height: 36px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    opacity: 0.5;
  }
}

.border {
  border: 2px solid $grey-20;
  background-color: white;
}

.downArrow {
  width: 20px;
  height: 15px;
  object-fit: contain;
}

.dropdownArrow {
  width: 25px;
  height: 20px;
  object-fit: contain;
}

.calendar {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: relative;
  width: 148px;
  height: px;
  background: #ffffff;
  border: 1px solid #0762c8;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.selectedFirst {
  background: #d1d1d1;
  color: #606060;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.selectedSecond {
  background: #d1d1d1;
}

.selectedThird {
  background: #d1d1d1;
  color: #606060;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.range {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 10px;
  width: 146px;
  height: 32px;
  flex: none;
  order: 0;
  flex-grow: 0;
  color: #606060;
}

.range.selected:first-child,
.range.firstItem:first-child,
.range:first-child.active,
.range:first-child:active,
.range:first-child:focus,
.range:first-child:hover {
  background: #d1d1d1;
  color: #606060;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.range.selected,
.range:not(:first-child):not(:last-child).active,
.range:not(:first-child):not(:last-child):active,
.range:not(:first-child):not(:last-child):focus,
.range:not(:first-child):not(:last-child):hover {
  background: #d1d1d1;
  color: #606060;
}

.range.selectedData,
.range.selected:last-child,
.range:last-child.active,
.range:last-child:active,
.range:last-child:focus,
.range:last-child:hover {
  background: #d1d1d1;
  color: #606060;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
