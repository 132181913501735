@import "../../variables.scss";

.logout {
  color: $red;
  font-weight: 500;

  & img {
    width: 11px;
    height: 14px;
    object-fit: contain;
  }
}

.downArrow {
  width: 20px;
  height: 15px;
  object-fit: contain;
}

.infoIcon {
  margin-left: 5px;
  margin-bottom: 3px;
  cursor: pointer;
  height: 16px;
  width: 16px;
}

.infoIcon1 {
  cursor: pointer;
  height: 16px;
  width: 16px;
}

.backButton {
  width: 24px;
  margin-bottom: 19px;
  margin-right: 60px;
  height: 24px;
  object-fit: contain;
}

.backButton1 {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  object-fit: contain;
}

.discardModel {
  background: $white;
  border: 1px solid $blue;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.header {
  border: 1px solid $grey-20;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.heading {
  border: 1px solid $grey-20;
}

.filterPop {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #d1d1d1;
  border-radius: 8px;
}

.crossDiv {
  margin-bottom: 3px;

  & img {
    width: 1rem;
    height: 1rem;
    object-fit: contain;
  }
}

.correctDiv {
  margin-bottom: 3px;

  & img {
    width: 1rem;
    height: 1rem;
    object-fit: contain;
  }
}

.bgLightRed {
  width: fit-content;
  height: 26px;
  background-color: $light-red;
  padding: 4px 8px;
  border-radius: 4px;
  align-items: center;
}

.bgGreen {
  width: fit-content;
  height: 26px;
  background-color: $light-green;
  padding: 4px 8px;
  border-radius: 4px;
  align-items: center;
}

.logo {
  height: 24px;
  width: 106.15px;
  object-fit: contain;
}

.bgGrey {
  background-color: $grey-05;
  border-left: 1px solid $grey-20;
  padding: 2rem 1rem;
}

.paddingLeft {
  padding: 2rem 1rem;
}

.separator {
  border: 1px solid $grey-20;
  width: 32px;
}

.Offcanvas {
  top: 0;
  bottom: 0;
  height: 100%;
  background-color: unset;
}

.Offcanvas1 {
  height: 91.5%;
}

.OffcanvasHeader {
  background: $grey-05;
  display: flex;
  padding: 1.48rem 2rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey-20;
}

.offCanvasTitle {
  color: $grey-70;
}

.left {
  border-right: 1px solid rgba(27, 27, 27, 0.2);
  height: 100%;
}

.datePicker {
  & input {
    border: 1px solid $grey-20;

    &::-webkit-calendar-picker-indicator {
      opacity: 1;
      display: block;
      background: url("../../assets/images/calendar.png") no-repeat;
    }
  }
}

.datePicker1 {
  & input {
    border: 1px solid $red;

    &::-webkit-calendar-picker-indicator {
      opacity: 1;
      display: block;
      background: url("../../assets/images/calendar.png") no-repeat;
    }
  }
}

.box {
  border-top: none;
  border: 1px solid $grey-20;
  border-radius: 0px 0px 8px 8px;
  height: 415px;
  overflow: auto;
}

.borderB {
  border-bottom: 1px solid rgba(27, 27, 27, 0.2);
  height: 32px;
  width: 100%;

  &:last-child {
    border-bottom: none;
  }
}

.boxHeder {
  border: 1px solid $grey-20;
  border-bottom: none;
  border-radius: 8px 8px 0px 0px;
}
.borderBHeader {
  height: 32px;
  width: 100%;
}

.borderTop {
  border-top: 1px solid rgba(27, 27, 27, 0.2);
  width: 100%;
  position: relative;
  bottom: 25px;
}

.buttonDischarge {
  padding: 8px 24px;
  color: $white;
  border-radius: 8px;
  margin-top: 14px;
  background-color: $red;
  width: fit-content;

  &:hover,
  &:focus {
    background: $dark-red;
    color: $white;
  }
}

.bgYellow {
  background: $yellow;
}

.bgGreen1 {
  background: #07bc0c;
}

.bgWhite1 {
  background: #fff;
}

.bgGray1 {
  background: #c5c5c5;
}

.bgRed {
  background: $red;
}

.bgBlue {
  background: $blue;
}

.bgGrey05 {
  background: $grey-05;
}

.correctDivBlue {
  background: $light-blue;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid $blue;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 50%;
  }
}

.correctDivRed {
  background: $light-red;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid $red;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 50%;
  }
}

.correctDivYellow {
  background: $light-yellow;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid $yellow;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 50%;
  }
}

.calendar {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 0;
}

.response_time {
  padding-right: 30px;
}

.wifiSignal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 4px;
  padding: 2px 6px;
  height: 22px;
  border-radius: 8px;
}

.wifiSignalData {
  text-align: center;
  font-size: 12px;
  padding-top: 8px;
  font-weight: 500;
  font-family: Inter;
  font-style: normal;
}
.wifiSignalImage {
  height: 12px;
  width: 16px;
  margin-bottom: 1px;
  margin-right: 5px;
  margin-left: 2px;
}
.excellent {
  background: #d7f0de;
  color: #009300;
}
.good {
  background: #cde0f4;
  color: #064ec5;
}
.fair {
  background: #ffe7bf;
  color: #ff8a00;
}
.weak {
  background: #f5d7d7;
  color: #c40000;
}

@media (min-width: $md) {
  .response_time {
    padding-right: 60px;
  }
  .bgWhite {
    min-height: 74.6vh;
  }

  .Offcanvas {
    height: 90.5vh;
    top: unset;
  }
}

@media (min-width: $lg) {
  .bgWhite {
    min-height: 81.5vh;
  }

  .bgGrey {
    padding: 1.23rem 4.2rem;
    border-left: 1px solid $grey-20;
  }

  .paddingLeft {
    padding: 1rem 3rem;
    margin: 1rem 1rem 1rem 1rem;
  }
}

@media (min-width: $xxl) {
  .response_time {
    padding-right: 80px;
  }
  .bgWhite {
    min-height: 82.2vh;
  }

  .paddingLeft {
    padding: 1rem 5rem;
    margin: 1rem 1rem 1rem 1rem;
  }

  .bgGrey {
    padding: 1.6rem 5rem;
    border-left: 1px solid $grey-20;
  }
}

.offline {
  color: #eb5500;
  letter-spacing: -0.02em;
  padding: 4.5px;
  font-size: 14px;
  font-weight: 500;
  height: 22px;
}

.offline1 {
  padding-top: 6px;
  opacity: 0.7;
  letter-spacing: -0.02em;
  font-weight: 500;
  height: 22px;
}

.alertHeader {
  font-size: 12px;
  padding-top: 1.6px;
}

// .statistics{
//   padding-top: 2px;
//   padding-bottom: 5.6px;
// }

@media (min-width: $lg) {
  .skeleton {
    width: 80px;
  }

  .alertHeader {
    font-size: 12px;
    padding-top: 1.6px;
  }

  .discardModel1 {
    width: 25%;
    margin-left: 550px;
  }

  .backButton {
    width: 24px;
    margin-bottom: 19px;
    margin-right: 0px;
    height: 24px;
    object-fit: contain;
  }

  .statistics {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .offline {
    color: #eb5500;
    letter-spacing: -0.02em;
    padding: 4.5px;
    font-size: 14px;
    font-weight: 500;
    height: 22px;
  }

  .offline1 {
    padding-top: 4.5px;
    opacity: 0.7;
    letter-spacing: -0.02em;
    font-weight: 500;
    height: 22px;
  }
}

@media (min-width: $xxl) {
  .skeleton {
    width: 100px;
  }

  .discardModel1 {
    width: 25%;
    margin-left: 550px;
  }

  .backButton {
    width: 24px;
    margin-bottom: 19px;
    height: 24px;
    object-fit: contain;
  }

  .statistics {
    padding-top: 1px;
    padding-bottom: 1.5px;
  }

  .alertHeader {
    font-size: 14px;
    padding-top: 2px;
  }

  .offline {
    color: #eb5500;
    letter-spacing: -0.02em;
    padding: 4.5px;
    font-size: 14px;
    font-weight: 500;
    height: 22px;
  }

  .offline1 {
    padding-top: 6px;
    opacity: 0.7;
    letter-spacing: -0.02em;
    font-weight: 500;
    height: 22px;
  }
}

.alertTime {
  padding-top: 6px;
  font-size: 14px;
  letter-spacing: -0.02em;
  font-weight: 500;
  height: 22px;
}

.nurseNameDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  margin-top: 6px;
  width: 100px;
  height: 20px;
  background: #e9e9e9;
  border-radius: 4px;
}

.nurseName {
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  padding-top: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #8d8d8d;
}
