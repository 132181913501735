@import "../../../../variables.scss";

.infoIcon {
  margin-left: 6px;
  margin-top: 6px;
  cursor: pointer;
  height: 16px;
  width: 16px;
}
.OffcanvasHeaderAddNurse {
  background: $grey-05;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  height: 20%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey-20;
}
.offCanvasTitleAddNurse {
  color: $grey-70;
  font-size: 20px;
}
.modelBody {
  height: 340px;
  padding-top: 10px;
}
.modelBodyHeaderAddNurseInput {
  box-sizing: border-box;
  width: 95%;
  height: 37px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  margin-top: 3px;
  margin: 10px 12px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 3px;
  &:hover,
  &:focus {
    border: 1px solid $blue;
  }
  &::placeholder {
    color: $grey-20;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.modelBodyHeaderAddNurseInput1 {
  box-sizing: border-box;
  width: 95%;
  height: 195px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  margin-top: 3px;
  margin: 10px 12px;
  padding: 10px;
  padding-left: 16px;
  padding-right: 16px;
  resize: vertical;
  &:hover,
  &:focus {
    border: 1px solid $blue;
  }
  &::placeholder {
    color: $grey-20;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

@media (min-width: $md) {
  .Offcanvas1AddNurse1 {
    height: 700px;
    width: 50%;
    margin: 50px 250px;
    border-radius: 8px;
  }
}

@media (min-width: $lg) {
  .Offcanvas1AddNurse1 {
    height: 700px;
    width: 60%;
    margin: 50px 200px;
    border-radius: 8px;
  }
  .modelBodyHeaderAddNurseInput {
    box-sizing: border-box;
    width: 95%;
    height: 37px;
    background: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    margin-top: 3px;
    margin: 10px 20px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 3px;
    &:hover,
    &:focus {
      border: 1px solid $blue;
    }
    &::placeholder {
      color: $grey-20;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
  .modelBodyHeaderAddNurseInput1 {
    box-sizing: border-box;
    width: 95%;
    height: 195px;
    background: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    margin-top: 3px;
    margin: 10px 20px;
    padding: 10px;
    padding-left: 16px;
    padding-right: 16px;
    resize: vertical;
    &:hover,
    &:focus {
      border: 1px solid $blue;
    }
    &::placeholder {
      color: $grey-20;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
}

@media (min-width: $xxl) {
  .Offcanvas1AddNurse1 {
    height: 800px;
    width: 44.5%;
    margin: 10px 400px;
    border-radius: 8px;
  }
  .modelBodyHeaderAddNurseInput {
    box-sizing: border-box;
    width: 95%;
    height: 37px;
    background: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    margin-top: 3px;
    margin: 10px 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 3px;
    &:hover,
    &:focus {
      border: 1px solid $blue;
    }
    &::placeholder {
      color: $grey-20;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
  .modelBodyHeaderAddNurseInput1 {
    box-sizing: border-box;
    width: 95%;
    height: 195px;
    background: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    margin-top: 3px;
    margin: 10px 16px;
    padding: 10px;
    padding-left: 16px;
    padding-right: 16px;
    resize: vertical;
    &:hover,
    &:focus {
      border: 1px solid $blue;
    }
    &::placeholder {
      color: $grey-20;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
}
