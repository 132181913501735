@import "../../../../variables.scss";

.Offcanvas {
  top: 0;
  bottom: 0;
  height: 100%;
  background-color: unset;
}

.Offcanvas1 {
  height: 91.5%;
}

.OffcanvasHeader {
  background: $grey-05;
  display: flex;
  padding: 1.45rem 2rem;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $grey-20;
}

.offCanvasTitle {
  color: $grey-70;
}

.modelBodyData {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  height: 494px;
  border-bottom: 1px solid lightgray;
}

.modelBodyHeader {
  height: 39px;
  background: #ffffff;
  border-bottom: 1px solid #e9e9e9;
}

.modelBody {
  height: 350px;
}

.modelBody1 {
  height: 188px;
}

.modelBodyHeaderAddNurse {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  height: 37px;
  background: #ffffff;
  border-bottom: 1px solid #e9e9e9;
}
.downArrow {
  width: 24px;
  height: 24px;
}

.modelBodyHeaderAddNurse1 {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-left: 24px;
  height: 99px;
  background: #f9f9f9;
  border-bottom: 1px solid #e9e9e9;
}

.modelBodyHeaderAddNurseInput {
  box-sizing: border-box;
  width: 220px;
  height: 37px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  margin-top: 4px;
  padding-left: 16px;
  padding-bottom: 3px;
  &:hover,
  &:focus {
    border: 1px solid $blue;
  }
  &::placeholder {
    color: $grey-20;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.modelBodyHeaderAddNurseInputErr {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-bottom: 3px;
  background: $white;
  border: 1px solid $red;
  color: $grey-70;
  box-sizing: border-box;
  border-radius: 8px;
  width: 220px;
  height: 37px;
  margin: 4px 0px;
  &::placeholder {
    color: $grey-20;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.modelBodyHeaderAddNurseButton {
  background: $blue;
  border: 1px solid $blue;
  box-sizing: border-box;
  border-radius: 8px;
  color: $white;
  width: 75px;
  height: 37px;
  box-sizing: border-box;
  align-items: center;
  margin-top: 21px;
  margin-right: 35px;
  font-weight: normal;
  &:hover,
  &:focus {
    background: $dark-blue;
  }
}

.modelBodyHeader2 {
  display: flex;
  justify-content: space-around;
  height: 110px;
  background: #f9f9f9;
  border-bottom: 1px solid #e9e9e9;
}

.modelBodyImage1 {
  box-sizing: border-box;
  padding-left: 14px;
  padding-top: 3px;
  margin-top: 10px;
  width: 88px;
  height: 88px;
  opacity: 50%;
  border-radius: 8px;
}

.clicked {
  box-sizing: border-box;
  padding-left: 14px;
  padding-top: 3px;
  margin-top: 10px;
  width: 88px;
  height: 88px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
}

.morningIcon {
  width: 60px;
  height: 60px;
}

.modelBodyImageP {
  width: 46px;
  height: 18px;
  width: 60px;
  font-size: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #606060;
}

.modelBodyHeader3 {
  display: flex;
  height: 39px;
  background: #ffffff;
  border-bottom: 1px solid #e9e9e9;
}

.infoIcon {
  margin-top: 11px;
  cursor: pointer;
  height: 15px;
  width: 15px;
}

.modelBodyHeader4 {
  display: flex;
  height: 54px;
  background: #f9f9f9;
  border-bottom: 1px solid #e9e9e9;
}

.modelBodyHeaderP1 {
  padding: 10px;
  padding-left: 24px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  color: #8d8d8d;
}
.modelBodyHeaderP2 {
  padding: 10px;
  padding-left: 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  color: #8d8d8d;
}

.modelBodyHeaderP3 {
  padding: 22px;
  padding-left: 24px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  color: #606060;
}
.modelBodyHeaderP4 {
  padding-top: 22px;
  width: 150px;
  padding-left: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  color: #606060;
}
.modelBodyInput {
  box-sizing: border-box;
  position: absolute;
  width: 130px;
  height: 37px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  margin-top: 8px;
  padding-left: 10px;
  &:hover,
  &:focus {
    border: 1px solid $blue;
  }
  &::placeholder {
    color: $grey-20;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.customToastNurse {
  margin-left: 200px;
  width: 400px;
  right: 5.8rem !important;
  bottom: 1rem !important;
}
.toastStatusNurse {
  background: #f5d7d7;
  padding: 2px 4px;
}

.customProgressBar {
  display: none;
}

@media (min-width: $md) {
  .Offcanvas1AddNurse {
    height: 700px;
    width: 35%;
    margin: 50px 330px;
    border-radius: 8px;
  }

  .Offcanvas1AddNurse1 {
    height: 700px;
    width: 50%;
    margin: 50px 250px;
    border-radius: 8px;
  }

  .Offcanvas1AddNurse2 {
    height: 700px;
    width: 70%;
    margin: 50px 200px;
    border-radius: 8px;
  }

  .Offcanvas {
    height: 90.5vh;
    top: unset;
  }
}

@media (min-width: $lg) {
  .customToastNurse {
    margin-left: 200px;
    width: 414px;
    right: 12.7rem !important;
    bottom: 1rem !important;
  }
  .modelBody {
    height: 400px;
  }

  .modelBody1 {
    height: 188px;
  }

  .modelBodyHeader {
    height: 39px;
    background: #ffffff;
    border-bottom: 1px solid #e9e9e9;
  }

  .modelBodyHeaderAddNurse1 {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-left: 24px;
    height: 99px;
    background: #f9f9f9;
    border-bottom: 1px solid #e9e9e9;
  }

  .modelBodyHeaderAddNurseInput {
    box-sizing: border-box;
    width: 466px;
    height: 37px;
    background: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    margin-top: 4px;
    padding-left: 16px;
    padding-bottom: 5px;
    &:hover,
    &:focus {
      border: 1px solid $blue;
    }
    &::placeholder {
      color: $grey-20;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  .modelBodyHeaderAddNurseInputErr {
    background: $white;
    border: 1px solid $red;
    color: $grey-70;
    box-sizing: border-box;
    border-radius: 8px;
    width: 466px;
    height: 37px;
    border-radius: 8px;
    margin-top: 4px;
    padding-left: 16px;
    padding-bottom: 5px;
    &::placeholder {
      color: $grey-20;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  .modelBodyHeader3 {
    display: flex;
    height: 39px;
    background: #ffffff;
    border-bottom: 1px solid #e9e9e9;
  }

  .modelBodyHeader4 {
    display: flex;
    height: 54px;
    background: #f9f9f9;
    border-bottom: 1px solid #e9e9e9;
  }

  .modelBodyHeaderP1 {
    padding: 10px;
    padding-left: 24px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    color: #8d8d8d;
  }
  .modelBodyHeaderP2 {
    padding: 10px;
    padding-left: 110px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    color: #8d8d8d;
  }

  .modelBodyHeaderP3 {
    padding: 22px;
    padding-left: 24px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    color: #606060;
  }
  .modelBodyHeaderP4 {
    padding-top: 22px;
    width: 300px;
    padding-left: 115px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    color: #606060;
  }
  .modelBodyInput {
    box-sizing: border-box;
    position: absolute;
    width: 191px;
    height: 37px;
    background: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    margin-top: 8px;
    margin-left: 10px;
    padding-left: 16px;
    &:hover,
    &:focus {
      border: 1px solid $blue;
    }
    &::placeholder {
      color: $grey-20;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  .modelBodyData {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
    height: 494px;
    border-right: 1px solid lightgray;
    border-bottom: 0px solid lightgray;
  }

  .Offcanvas1AddNurse {
    height: 600px;
    width: 35%;
    height: fit-content;
    margin: 50px 320px;
    border-radius: 8px;
  }

  .Offcanvas1AddNurse1 {
    height: 700px;
    width: 60%;
    margin: 50px 200px;
    border-radius: 8px;
  }

  .Offcanvas1AddNurse2 {
    height: 700px;
    width: 80%;
    margin: 50px 110px;
    border-radius: 8px;
  }
}

@media (min-width: $xxl) {
  .customToastNurse {
    margin-left: 200px;
    width: 454px;
    right: 9.2rem !important;
    bottom: 1rem !important;
  }
  .modelBody {
    height: 400px;
  }
  .modelBody1 {
    height: 188px;
  }

  .modelBodyHeader {
    height: 39px;
    background: #ffffff;
    border-bottom: 1px solid #e9e9e9;
  }

  .modelBodyHeaderAddNurseInput {
    box-sizing: border-box;
    width: 566px;
    height: 37px;
    background: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    margin-top: 3px;
    padding-left: 16px;
    padding-bottom: 3px;
    &:hover,
    &:focus {
      border: 1px solid $blue;
    }
    &::placeholder {
      color: $grey-20;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  .modelBodyHeaderAddNurseInputErr {
    background: $white;
    border: 1px solid $red;
    color: $grey-70;
    box-sizing: border-box;
    border-radius: 8px;
    width: 566px;
    height: 37px;
    border-radius: 8px;
    margin-top: 3px;
    padding-left: 16px;
    padding-bottom: 5px;
    &::placeholder {
      color: $grey-20;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  .modelBodyHeader3 {
    display: flex;
    height: 39px;
    background: #ffffff;
    border-bottom: 1px solid #e9e9e9;
  }

  .modelBodyHeader4 {
    display: flex;
    height: 54px;
    background: #f9f9f9;
    border-bottom: 1px solid #e9e9e9;
  }

  .modelBodyHeaderP1 {
    padding: 8px;
    padding-left: 24px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    color: #8d8d8d;
  }
  .modelBodyHeaderP2 {
    padding: 8px;
    padding-left: 110px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    color: #8d8d8d;
  }

  .modelBodyHeaderP3 {
    padding: 22px;
    padding-left: 24px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    color: #606060;
  }
  .modelBodyHeaderP4 {
    padding-top: 22px;
    width: 300px;
    padding-left: 123px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    color: #606060;
  }
  .modelBodyInput {
    box-sizing: border-box;
    display: flex;
    width: 191px;
    height: 37px;
    background: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    margin-top: 8px;
    margin-left: 25px;
    padding-left: 16px;
    &:hover,
    &:focus {
      border: 1px solid $blue;
    }
    &::placeholder {
      color: $grey-20;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  .Offcanvas2AddNurse {
    height: 700px;
    width: 50%;
    margin: 50px 350px;
    border-radius: 8px;
  }

  .Offcanvas1AddNurse {
    height: 700px;
    width: 27%;
    margin: 50px 518px;
    border-radius: 8px;
  }

  .Offcanvas1AddNurse1 {
    height: 800px;
    width: 50%;
    margin: 10px 350px;
    border-radius: 8px;
  }

  .Offcanvas1AddNurse2 {
    height: 700px;
    width: 70%;
    margin: 50px 200px;
    border-radius: 8px;
  }
}

.addNurse {
  background: #ffffff;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px 8px 0px 0px;
  align-items: center;
}

.redDot {
  margin-top: 9px;
  background: #cf3636;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.addNursePara {
  text-align: center;
  color: #606060;
  padding-right: 10px;
  padding-bottom: 10px;
  justify-content: center;
  font-weight: 500;
  padding-top: 4px;
  font-size: 12px;
}

.addNursePara1 {
  overflow: hidden;
  cursor: pointer;
  font-weight: 500;
  padding-bottom: 10px;
  padding-right: 20px;
  font-size: 12px;
  color: #0762c8;
}

.addNursePara2 {
  overflow: hidden;
  font-weight: 500;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-top: 4px;
  font-size: 12px;
  color: #cf3636;
}

.morningSmallIcon {
  width: 24px;
  height: 24px;
}

.OffcanvasHeaderAddNurse {
  background: $grey-05;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  // position: absolute;
  height: 20%;
  padding: 1rem 1.5rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey-20;
}

.offCanvasTitleAddNurse {
  color: $grey-70;
  font-size: 20px;
}

.modelCreateNurse {
  cursor: pointer;
  padding-top: 22px;
  padding-left: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #0762c8;
}
