@import "../../../../../variables.scss";

.OffcanvasHeaderShift {
  background: $grey-05;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  height: 20%;
  padding: 1rem 1.5rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey-20;
}

.offCanvasTitleShift {
  color: $grey-70;
  font-size: 20px;

}

.OffcanvasShift {
  height: 450px;
  border-radius: 8px;
  margin: 150px 0px;
}
.OffcanvasShiftText {
  width: 340px;
  height: 44px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #606060;
  margin-left: 8px;
}
.divMorningToEvening {
  display: flex;
  justify-content: center;
  align-items: center;
}
.morningToEvening {
  width: 340px;
  height: 100px;
  margin: 30px 0px;
}

@media (min-width: $lg) {
  .modelBody {
    height: 400px;
  }
  .OffcanvasShift {
    height: 450px;
    width: 526px;
    margin: 150px 250px;
    border-radius: 8px;
  }
  .OffcanvasShiftText {
    width: 397px;
    height: 44px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #606060;
  }
  .morningToEvening {
    width: 388px;
    height: 114px;
    margin: 30px 60px;
  }
}

@media (min-width: $xxl) {
  .modelBody {
    height: 400px;
  }
  .OffcanvasShift {
    height: 450px;
    width: 526px;
    margin: 150px 456px;
    border-radius: 8px;
  }
  .OffcanvasShiftText {
    width: 397px;
    height: 44px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #606060;
  }
  .morningToEvening {
    width: 388px;
    height: 114px;
    margin: 30px 70px;
  }
}
