@import "../../variables.scss";
.heading {
  border: 1px solid $grey-20;
  // height: 78px;
}
.headingLeft {
  border: 1px solid $grey-20;
  height: 78px;
}
.inputDiv {
  & input {
    width: 250px;
  }
}

.heatmap {
  width: 600px;
  height: 250px;
}

.footer {
  display: flex;
  flex-direction: column;
}

.infoIcon {
  margin-left: 5px;
  margin-top: 8px;
  cursor: pointer;
  height: 18px;
  width: 18px;
}

.tooltipTitle {
  color: #fff;
  font-weight: 600;
}

@media (min-width: $md) {
  .heatmap {
    width: 1000px;
    height: 500px;
  }
  .footer {
    display: flex;
    flex-direction: row;
    margin-right: 50px;
  }
}

@media (min-width: $lg) {
  .heatmap {
    width: 1000px;
    // height: 580px;
  }
  .heading {
    border: 1px solid $grey-20;
    width: 71.9%;
    height: 78px;
  }
  .headingLeft {
    border: 1px solid $grey-20;
    width: 28.11%;
    height: 78px;
  }
}
@media (min-width: $xxl) {
  .heatmap {
    width: 1000px;
    height: 600px;
  }
  .heading {
    border: 1px solid $grey-20;
    width: 72.8%;
    height: 78px;
  }
  .headingLeft {
    border: 1px solid $grey-20;
    width: 27.21%;
    height: 78px;
  }
}
.Offcanvas {
  top: 0;
  bottom: 0;
  height: 100%;
  background-color: unset;
}
.Offcanvas1 {
  height: 91.5%;
}
.OffcanvasHeader {
  background: $grey-05;
  display: flex;
  padding: 1.48rem 2rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey-20;
}
.offCanvasTitle {
  color: $grey-70;
}
.greenDot {
  background: $green;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.box {
  display: flex;
  flex-direction: column;
}

.redDot {
  background: #ff0000;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.redDot1 {
  background: #f07300;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.orangeDot {
  background: #ffa500;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.green1Dot {
  background: #33cc33;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.blueDot {
  background: #0033ff;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.redBox {
  background: #fff;
  width: 15px;
  height: 15px;
  border: 1.5px solid #ff0000;
}

.logo {
  width: 12px;
  height: 12px;
}

.image {
  width: 100%;
  height: 550px;
  padding: 10px 0px;
  padding-bottom: 20px;
}
