@import "../../variables.scss";

.logout {
  color: $red;
  font-weight: 500;

  & img {
    width: 11px;
    height: 14px;
    object-fit: contain;
  }
}

.calendar {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 0;
}

.discardModel {
  background: $white;
  border: 1px solid $blue;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.header {
  border: 1px solid $grey-20;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.heading {
  border: 1px solid $grey-20;
}

.crossDiv {
  margin-bottom: 3px;

  & img {
    width: 1rem;
    height: 1rem;
    object-fit: contain;
  }
}

.correctDiv {
  margin-bottom: 3px;

  & img {
    width: 1rem;
    height: 1rem;
    object-fit: contain;
  }
}

.downArrow {
  width: 20px;
  height: 15px;
  object-fit: contain;
}

.backButton {
  width: 24px;
  margin-bottom: 19px;
  margin-right: 60px;
  height: 24px;
  object-fit: contain;
}

.bgLightRed {
  width: fit-content;
  height: 26px;
  background-color: $light-red;
  padding: 4px 8px;
  border-radius: 4px;
  align-items: center;
}

.bgGreen {
  width: fit-content;
  height: 26px;
  background-color: $light-green;
  padding: 4px 8px;
  border-radius: 4px;
  align-items: center;
}

.bgGrey {
  background-color: $grey-05;
  border-left: 1px solid $grey-20;
  padding: 2rem 1rem;
}

.separator {
  border: 1px solid $grey-20;
  width: 32px;
}

.Offcanvas {
  top: 0;
  bottom: 0;
  height: 100%;
  background-color: unset;
}

.Offcanvas1 {
  height: 91.5%;
}

.OffcanvasHeader {
  background: $grey-05;
  display: flex;
  padding: 1.48rem 2rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey-20;
}

.offCanvasTitle {
  color: $grey-70;
}

.left {
  border-right: 1px solid rgba(27, 27, 27, 0.2);
  height: 100%;
}

.datePicker {
  & input {
    border: 1px solid $grey-20;

    &::-webkit-calendar-picker-indicator {
      opacity: 1;
      display: block;
      background: url("../../assets/images/calendar.png") no-repeat;
    }
  }
}
.datePicker1 {
  & input {
    border: 1px solid $red;

    &::-webkit-calendar-picker-indicator {
      opacity: 1;
      display: block;
      background: url("../../assets/images/calendar.png") no-repeat;
    }
  }
}

.box {
  border: 1px solid $grey-20;
  border-radius: 8px;
  overflow: auto;
  margin-top: 100px;
}

.box1 {
  border: 1px solid $grey-20;
  border-radius: 8px;
  overflow: auto;
  margin-top: 100px;
}

.borderB {
  border-bottom: 1px solid rgba(27, 27, 27, 0.2);
  height: 32px;
  width: 100%;

  &:first-child {
    border-radius: 8px 8px 0 0;
  }

  &:last-child {
    border-bottom: 0;
    border-radius: 0 0 8px 8px;
  }
}

.borderB1 {
  cursor: pointer;
  border-bottom: 1px solid rgba(27, 27, 27, 0.2);
  // padding-bottom: 100px;
  height: 80px;
  width: 100%;

  &:first-child {
    border-radius: 8px 8px 0 0;
  }

  &:last-child {
    border-bottom: 0;
    border-radius: 0 0 8px 8px;
  }
}

.borderB2 {
  cursor: pointer;
  border-right: 1px solid rgba(27, 27, 27, 0.2);
  height: 80px;
  width: 100%;

  &:last-child {
    border-bottom: 0;
    border-radius: 0 0 0px 0px;
    border-right: 0px solid rgba(27, 27, 27, 0.2);
  }
}

.redText {
  color: $red;
}

.infoIcon {
  margin-left: 5px;
  margin-bottom: 1px;

  cursor: pointer;
  height: 16px;
  width: 16px;
}

.infoIcon1 {
  cursor: pointer;
  height: 16px;
  width: 16px;
}

.tooltipTitle {
  color: #fff;
  font-weight: 600;
}

.nextTime {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  padding: 2px 6px;
  height: 22px;
  background: #d7f0de;
  border-radius: 8px;
}

.nextTimeData {
  text-align: center;
  font-size: 12px;
  color: #009300;
  padding-top: 8px;
  font-weight: 500;
  font-family: Inter;
  font-style: normal;
}

.wifiSignal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 4px;
  padding: 2px 6px;
  height: 22px;
  border-radius: 8px;
}

.wifiSignalData {
  text-align: center;
  font-size: 12px;
  padding-top: 8px;
  font-weight: 500;
  font-family: Inter;
  font-style: normal;
}
.wifiSignalImage {
  height: 12px;
  width: 16px;
  margin-bottom: 1px;
  margin-right: 5px;
  margin-left: 2px;
}
.excellent {
  background: #d7f0de;
  color: #009300;
}
.good {
  background: #cde0f4;
  color: #064ec5;
}
.fair {
  background: #ffe7bf;
  color: #ff8a00;
}
.weak {
  background: #f5d7d7;
  color: #c40000;
}

.bgYellow {
  background: $yellow;
}

.bgGreen1 {
  background: #07bc0c;
}

.bgGray1 {
  background: #c5c5c5;
}

.bgRed {
  background: $red;
}

.bgBlue {
  background: $blue;
}

.bgGrey05 {
  background: $grey-05;
}

.greenDot {
  background: $green;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.redDot {
  background: #f07300;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.borderTop {
  border-top: 1px solid rgba(27, 27, 27, 0.2);
  width: 100%;
  position: relative;
  bottom: 25px;
}

.buttonDischarge {
  padding: 8px 24px;
  color: $white;
  border-radius: 8px;
  margin-top: 14px;
  background-color: $red;
  width: fit-content;

  &:hover,
  &:focus {
    background: $dark-red;
    color: $white;
  }
}

.logo {
  height: 24px;
  width: 106.15px;
  object-fit: contain;
}

.paddingLeft {
  padding: 2rem 1rem;
}

@media (min-width: $md) {
  .bgWhite {
    min-height: 74.6vh;
  }

  .Offcanvas {
    height: 90.5vh;
    top: unset;
  }

  .bgGrey {
    width: 75%;
    border-left: 1px solid $grey-20;
  }
}

@media (min-width: $lg) {
  .bgWhite {
    min-height: 80.5vh;
  }

  .discardModel1 {
    width: 25%;
    margin-left: 550px;
  }

  .bgGrey {
    padding: 1.5rem 4.2rem;
    width: 71.9%;
    border-left: 1px solid $grey-20;
  }

  .paddingLeft {
    padding: 1rem 3rem;
    margin: 1rem 1rem 1rem 1rem;
  }
}

@media (min-width: $xxl) {
  .bgWhite {
    min-height: 82.2vh;
  }

  .discardModel1 {
    width: 25%;
    margin-left: 550px;
  }

  .paddingLeft {
    padding: 1rem 5rem;
    margin: 1rem 1rem 1rem 1rem;
  }

  .bgGrey {
    padding: 1.8rem 5rem;
    width: 72.8%;
    border-left: 1px solid $grey-20;
  }
}

//legend
.dLImageLegendDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dLImageLegendBed {
  width: 12px;
  height: 18px;
}

.dLImageLegendApple {
  width: 16px;
  height: 20px;
}

.dLImageLegend {
  width: 16px;
  height: 20px;
}

//insideGraph
.dLImageInsideDiv {
  padding-bottom: 110px;
}

.dLOfflineImageInsideDiv {
  padding-bottom: 10px;
}

.dLImage {
  width: 12px;
  height: 20px;
}

.dLOfflineImage {
  width: 20px;
  height: 20px;
}

.dL {
  background-color: #39b35a;
  display: block;
  border-radius: 40% 55% 1% 80% / 40% 80% 1% 55%;
  transform: rotate(45deg);
  height: 20px;
  width: 20px;
}

.dLAlert {
  background-color: #0762c8;
  display: block;
  border-radius: 40% 55% 1% 80% / 40% 80% 1% 55%;
  transform: rotate(45deg);
  height: 20px;
  width: 20px;
}
.overlap {
  position: absolute;
  top: -20px; /* Adjust this value as needed */
  left: -10px;
}

.overlapBedExit {
  position: absolute;
  top: -77px; /* Adjust this value as needed */
  left: -5px;
}

.dLP {
  display: block;
  transform: rotate(-45deg);
  padding-left: 5px;
  padding-top: 3px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #ffffff;
}

.dL1 {
  background-color: #39b35a;
  display: block;
  border-radius: 40% 55% 1% 80% / 40% 80% 1% 55%;
  transform: rotate(45deg);
  // padding: 7px;
  height: 25px;
  width: 25px;
}

.skeleton {
  width: 80px;
}

.offline1 {
  display: flex;
  opacity: 0.7;
  margin-top: 4.5px;
  font-size: 14px;
  letter-spacing: -0.02em;
  font-weight: 500;
  height: 22px;
}

.offline {
  color: #eb5500;
  margin-top: 4.5px;
  display: flex;
  margin-right: 15px;
  font-size: 14px;
  letter-spacing: -0.02em;
  font-weight: 500;
  height: 22px;
}

@media (max-width: 540px) {
  .boxLegend {
    display: flex;
    flex-direction: column;
  }
  .boxLegendFirst {
    padding-left: 30px;
  }
  .dashedLine {
    position: absolute;
    width: 24px;
    height: 0px;
    left: 80px;
    top: 61px;
    border: 1px dashed #ffaf2b;
  }
  .MobilityLine {
    position: absolute;
    width: 24px;
    height: 0px;
    left: 95px;
    top: 78px;
    border: 1px solid #39b35a;
  }
  .dLImageLegendDiv {
    display: none;
  }
}

@media (max-width: 767px) {
  .dLP1 {
    padding-left: 5px;
    padding-top: 15px;
    font-size: 10px;
  }
}

@media (min-width: 768px) {
  .dLP1 {
    padding-left: 5px;
    padding-top: 15px;
    font-size: 10px;
  }
}

@media (min-width: $md) {
  .dashedLine {
    position: absolute;
    width: 24px;
    height: 0px;
    left: 140px;
    top: 61px;
    border: 1px dashed #ffaf2b;
  }
  .MobilityLine {
    position: absolute;
    width: 24px;
    height: 0px;
    left: 300px;
    top: 61px;
    border: 1px solid #39b35a;
  }
}

@media (min-width: $lg) {
  .skeleton {
    width: 80px;
  }

  .backButton {
    width: 24px;
    margin-bottom: 19px;
    margin-right: 0px;
    height: 24px;
    object-fit: contain;
  }

  .offline1 {
    display: flex;
    margin-top: 4.5px;
    font-size: 14px;
    letter-spacing: -0.02em;
    font-weight: 500;
    height: 22px;
  }

  .offline {
    color: #eb5500;
    margin-top: 4.5px;
    display: flex;
    margin-right: 15px;
    font-size: 14px;
    letter-spacing: -0.02em;
    font-weight: 500;
    height: 22px;
  }
}

@media (min-width: $xxl) {
  .dashedLine {
    position: absolute;
    width: 24px;
    height: 0px;
    left: 265px;
    top: 63px;
    border: 1px dashed #ffaf2b;
  }
  .MobilityLine {
    position: absolute;
    width: 24px;
    height: 0px;
    left: 445px;
    top: 63px;
    border: 1px solid #39b35a;
  }
  .dLP1 {
    padding-left: 5px;
    padding-top: 15px;
    font-size: 12px;
  }

  .skeleton {
    width: 100px;
  }

  .offline1 {
    display: flex;
    margin-top: 1.5px;
    font-size: 14px;
    letter-spacing: -0.02em;
    font-weight: 500;
    height: 22px;
  }

  .backButton {
    width: 24px;
    margin-bottom: 19px;
    height: 24px;
    object-fit: contain;
  }

  .offline {
    color: #eb5500;
    margin-top: 2px;
    display: flex;
    margin-right: 15px;
    font-size: 14px;
    letter-spacing: -0.02em;
    font-weight: 500;
    height: 22px;
  }
}
